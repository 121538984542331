import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Aggregation Panels: Added a new hierarchy level for forms question’s answers presentation to add metrics for aggregation panels.`}</li>
      <li parentName="ul">{`Aggregation Panels: Added Wrapup Codes as metrics on Aggregation Panels and on rules.`}</li>
      <li parentName="ul">{`Push Notification: Fixed push notifications on Safari’s latest version (v16) and on Edge browsers.`}</li>
      <li parentName="ul">{`@Sidetalk/react: Upgraded the react-querybuilder package version and moved the QueryBuilder component to @sidetalk/react with the new Coremedia visual alignment.`}</li>
      <li parentName="ul">{`Rules: Added an option to select empty/not empty cells for any fields on rules.`}</li>
      <li parentName="ul">{`Configurations: Added new Redis Cluster node to the configuration files on CCC DC-NOS.**`}</li>
      <li parentName="ul">{`Bug Fix: Validated missing contact prop in nextProps.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      